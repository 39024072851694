
import {Vue, Component, Prop, Watch} from "vue-property-decorator";

@Component({})
export default class KarsilastirmaliGrafikColChart extends Vue {
  tahsilatOraniTitle = ["Toplam Tahsilat", "Tahsilat Oranı", "Derdest Kısmi Toplam", "Hitam Tahsilat"]
  loading: boolean = false;
  selectedOption:any= [];
  chartData:any = []
  chartDataHitam:any = []
  options = {
    series: [
      {
        name: 'Geçen Hafta',
        data: {},
        color: '#5D7285',
      },
      {
        name: 'Bu Hafta',
        data: {},
        color: '#5FB3F6',
      },
    ],
    chart: {
      type: 'bar',
      offsetY: 40,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '25%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#5D7285", "#5FB3F6"]
    },
    xaxis: {
      categories: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe','Cuma','Cumartesi','Pazar'],
      offsetY: -10,
    },
    yaxis: {
      title: {
        text: '₺ (bin)'
      }
    },
    fill: {
      opacity: 1,
      colors: ["#5D7285", "#5FB3F6"]
    },
    tooltip: {
      y: {
        formatter: function (val: string) {
          return "₺ " + val
        }
      }
    }
  }

  @Watch('selectedOption')
  selectOption(item:any){
    if(item === 'Toplam Tahsilat'){
      this.toplamTahsilat()
    }
    if (item === 'Derdest Kısmi Toplam'){
      this.derdestToplam()
    }
    if (item === 'Hitam Tahsilat'){
      this.hitamToplam()
    }
    if (item === 'Tahsilat Oranı'){
      this.tahsilatOrani()
    }
  }

  derdestToplam(){
    this.options.series = [
      {
        name: 'Geçen Hafta',
        data: [...this.chartData],
        color: '#5D7285',
      },
      {
        name: 'Bu Hafta',
        data: [...this.chartData.slice().reverse()],
        color: '#5FB3F6',
      },
    ]
  }

  hitamToplam(){
    this.options.series = [
      {
        name: 'Geçen Hafta',
        data: [...this.chartDataHitam,],
        color: '#5D7285',
      },
      {
        name: 'Bu Hafta',
        data: [...this.chartDataHitam.slice().reverse()],
        color: '#5FB3F6',
      },
    ]
  }

  toplamTahsilat(){
    let tahsilatToplamiIlkGun = Number((this.chartData[0]) + Number(this.chartDataHitam[0])).toFixed(0);
    let tahsilatToplamiIkinciGun = Number((this.chartData[1]) + Number(this.chartDataHitam[1])).toFixed(0);
    let tahsilatToplamiUcuncuGun = Number((this.chartData[2]) + Number(this.chartDataHitam[2])).toFixed(0);
    let tahsilatToplamiDorduncuGun =  Number((this.chartData[3]) + Number(this.chartDataHitam[3])).toFixed(0);
    let tahsilatToplamiBesinciGun =  Number((this.chartData[4]) + Number(this.chartDataHitam[4])).toFixed(0);
    let tahsilatToplamiAltinciGun =  Number((this.chartData[5]) + Number(this.chartDataHitam[5])).toFixed(0);
    let tahsilatToplamiYedinciGun =  Number((this.chartData[6]) + Number(this.chartDataHitam[6])).toFixed(0);

    this.options.series =[
      {
        name: 'Geçen Hafta',
        data: [tahsilatToplamiIlkGun, tahsilatToplamiIkinciGun, tahsilatToplamiUcuncuGun, tahsilatToplamiDorduncuGun,tahsilatToplamiBesinciGun,tahsilatToplamiAltinciGun,tahsilatToplamiYedinciGun],
        color: '#5D7285',
      },
      {
        name: 'Bu Hafta',
        data: [tahsilatToplamiYedinciGun,tahsilatToplamiAltinciGun,tahsilatToplamiBesinciGun,tahsilatToplamiDorduncuGun, tahsilatToplamiUcuncuGun, tahsilatToplamiIkinciGun, tahsilatToplamiIlkGun],
        color: '#5FB3F6',
      },
    ]
  }

  tahsilatOrani(){
    let tahsilatToplamiIlkGun = Number(this.chartData[0]) + Number(this.chartDataHitam[0]);
    let tahsilatToplamiIkinciGun = Number(this.chartData[1]) + Number(this.chartDataHitam[1])
    let tahsilatToplamiUcuncuGun = Number(this.chartData[2]) + Number(this.chartDataHitam[2])
    let tahsilatToplamiDorduncuGun =  Number(this.chartData[3]) + Number(this.chartDataHitam[3])
    let tahsilatToplamiBesinciGun =  Number(this.chartData[4]) + Number(this.chartDataHitam[4])
    let tahsilatToplamiAltinciGun =  Number(this.chartData[5]) + Number(this.chartDataHitam[5])
    let tahsilatToplamiYedinciGun =  Number(this.chartData[6]) + Number(this.chartDataHitam[6])

    let tahsilatOraniIlkGun = Number((this.chartDataHitam[0])/tahsilatToplamiIlkGun).toFixed(2);
    let tahsilatOraniIkinciGun = Number((this.chartDataHitam[1])/tahsilatToplamiIkinciGun).toFixed(2);
    let tahsilatOraniUcuncuGun = Number((this.chartDataHitam[2])/tahsilatToplamiUcuncuGun).toFixed(2);
    let tahsilatOraniDorduncuGun =  Number((this.chartDataHitam[3])/tahsilatToplamiDorduncuGun).toFixed(2);
    let tahsilatOraniBesinciGun =  Number((this.chartDataHitam[3])/tahsilatToplamiBesinciGun).toFixed(2);
    let tahsilatOraniAltinciGun =  Number((this.chartDataHitam[3])/tahsilatToplamiAltinciGun).toFixed(2);
    let tahsilatOraniYedinciGun =  Number((this.chartDataHitam[3])/tahsilatToplamiYedinciGun).toFixed(2);


    this.options.series =[
      {
        name: 'Geçen Hafta',
        data: [tahsilatOraniIlkGun, tahsilatOraniIkinciGun, tahsilatOraniUcuncuGun, tahsilatOraniDorduncuGun,tahsilatOraniBesinciGun,tahsilatOraniAltinciGun,tahsilatOraniYedinciGun],
        color: '#5D7285',
      },
      {
        name: 'Bu Hafta',
        data: [tahsilatOraniYedinciGun,tahsilatOraniAltinciGun,tahsilatOraniBesinciGun,tahsilatOraniDorduncuGun, tahsilatOraniUcuncuGun, tahsilatOraniIkinciGun, tahsilatOraniIlkGun],
        color: '#5FB3F6',
      },
    ]
  }

  mounted(){
    this.selectedOption = 'Toplam Tahsilat';
    this.fetchData()
      .then(() => {
        this.toplamTahsilat();
      })
  }

  async fetchData(){
    try{
      const response = await this.$http.post('/api/v1/karsilastirmali-tahsilat-raporlama',{
        "userId" : this.$store.state.user.id,
        "basTar" : "2020-01-01",
        "bitTar" : "2024-01-01",
        "interval" : "weekly"
      });

      const data = response.data

      for (var i = 0; i < 7; i++) {
        this.chartData.push(data[i].derdesttoplam);
        this.chartDataHitam.push(data[i].hitamtoplam);

      }
    }catch (error) {
      this.loading = true;

      console.error('hata:', error);
    }
  }
}
