
import {Vue, Component, Prop, Watch} from "vue-property-decorator";

@Component({})
export default class KarsilastirmaliGrafikColAyChart extends Vue {
  tahsilatOraniTitle = ["Toplam Tahsilat", "Tahsilat Oranı", "Derdest Kısmi Toplam", "Hitam Tahsilat"]
  loading: boolean = false;
  selectedOption:any= [];
  chartData:any = []
  chartDataHitam:any = []
  options = {
    series: [
      {
        name: 'Geçen Ay',
        data: {},
        color: '#5D7285',
      },
      {
        name: 'Bu Ay',
        data: {},
        color: '#5FB3F6',
      },
    ],
    chart: {
      type: 'bar',
      offsetY: 40,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '25%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#5D7285", "#5FB3F6"]
    },
    xaxis: {
      categories: ['1.Hafta', '2.Hafta', '3.Hafta', '4.Hafta',],
      offsetY: -10,
    },
    yaxis: {
      title: {
        text: '₺ (bin)'
      }
    },
    fill: {
      opacity: 1,
      colors: ["#5D7285", "#5FB3F6"]
    },
    tooltip: {
      y: {
        formatter: function (val: string) {
          return "₺ " + val
        }
      }
    }
  }

  @Watch('selectedOption')
  selectOption(item:any){
    if(item === 'Toplam Tahsilat'){
      this.toplamTahsilat()
    }
    if (item === 'Derdest Kısmi Toplam'){
      this.derdestToplam()
    }
    if (item === 'Hitam Tahsilat'){
      this.hitamToplam()
    }
    if (item === 'Tahsilat Oranı'){
      this.tahsilatOrani()
    }
  }

  derdestToplam(){
    this.options.series = [
      {
        name: 'Geçen Ay',
        data: [...this.chartData],
        color: '#5D7285',
      },
      {
        name: 'Bu Ay',
        data: [...this.chartData.slice().reverse()],
        color: '#5FB3F6',
      },
    ]
  }

  hitamToplam(){
    this.options.series = [
      {
        name: 'Geçen Ay',
        data: [...this.chartDataHitam,],
        color: '#5D7285',
      },
      {
        name: 'Bu Ay',
        data: [...this.chartDataHitam.slice().reverse()],
        color: '#5FB3F6',
      },
    ]
  }

  toplamTahsilat(){
    let tahsilatToplamiIlkAy = Number((this.chartData[0]) + Number(this.chartDataHitam[0])).toFixed(0);
    let tahsilatToplamiIkinciAy = Number((this.chartData[1]) + Number(this.chartDataHitam[1])).toFixed(0);
    let tahsilatToplamiUcuncuAy = Number((this.chartData[2]) + Number(this.chartDataHitam[2])).toFixed(0);
    let tahsilatToplamiDorduncuAy =  Number((this.chartData[3]) + Number(this.chartDataHitam[3])).toFixed(0);

    this.options.series =[
      {
        name: 'Geçen Ay',
        data: [tahsilatToplamiIlkAy, tahsilatToplamiIkinciAy, tahsilatToplamiUcuncuAy, tahsilatToplamiDorduncuAy],
        color: '#5D7285',
      },
      {
        name: 'Bu Ay',
        data: [tahsilatToplamiDorduncuAy, tahsilatToplamiUcuncuAy, tahsilatToplamiIkinciAy, tahsilatToplamiIlkAy],
        color: '#5FB3F6',
      },
    ]
  }

  tahsilatOrani(){
    let tahsilatToplamiIlkAy = Number( this.chartData[0]) + Number(this.chartDataHitam[0]);
    let tahsilatToplamiIkinciAy = Number( this.chartData[1]) + Number(this.chartDataHitam[1]);
    let tahsilatToplamiUcuncuAy = Number( this.chartData[2]) + Number(this.chartDataHitam[2])
    let tahsilatToplamiDorduncuAy =  Number( this.chartData[3]) + Number(this.chartDataHitam[3])

    let tahsilatOraniIlkAy = Number((this.chartDataHitam[0])/tahsilatToplamiIlkAy).toFixed(2);
    let tahsilatOraniIkinciAy = Number((this.chartDataHitam[1])/tahsilatToplamiIkinciAy).toFixed(2);
    let tahsilatOraniUcuncuAy = Number((this.chartDataHitam[2])/tahsilatToplamiUcuncuAy).toFixed(2);
    let tahsilatOraniDorduncuAy =  Number((this.chartDataHitam[3])/tahsilatToplamiDorduncuAy).toFixed(2);

    this.options.series =[
      {
        name: 'Geçen Ay',
        data: [tahsilatOraniIlkAy, tahsilatOraniIkinciAy, tahsilatOraniUcuncuAy, tahsilatOraniDorduncuAy],
        color: '#5D7285',
      },
      {
        name: 'Bu Ay',
        data: [tahsilatOraniDorduncuAy, tahsilatOraniUcuncuAy, tahsilatOraniIkinciAy, tahsilatOraniIlkAy],
        color: '#5FB3F6',
      },
    ]
  }

  mounted(){
    this.selectedOption = 'Toplam Tahsilat';
    this.fetchData()
      .then(() => {
        this.toplamTahsilat();
      })
  }

  async fetchData(){
    try{
      const response = await this.$http.post('/api/v1/karsilastirmali-tahsilat-raporlama',{
        "userId" : this.$store.state.user.id,
        "basTar" : "2020-01-01",
        "bitTar" : "2024-01-01",
        "interval" : "monthly"
      });

      const data = response.data

      for (var i = 0; i < 4; i++) {
        this.chartData.push(data[i].derdesttoplam);
        this.chartDataHitam.push(data[i].hitamtoplam);

      }
    }catch (error) {
      this.loading = true;

      console.error('hata:', error);
    }
  }
}
