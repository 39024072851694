
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import MuvekkilSecim from "@/components/infos/raporlama-ofispro/helper-comps/MuvekkilSecim.vue";
@Component({
  components: {
    MuvekkilSecim

  }
})

export default class KarsilastirmaliYilSecim extends Mixins(ObjectInputMixin){
  @Prop()items!:Array<string>
  isMenuOpen: boolean = false;
  isMenuOpen2: boolean = false;
  @Prop()selectedItem!:null
  @Prop()selectedItem2!:null


  selectItem(item:any) {

    this.selectedItem = item;

    if (this.selectedItem) {
       this.selectedItem = item
      this.isMenuOpen=false
    }

    this.$emit('selectedItemBaslangicTarih',this.selectedItem)
  }

  selectItem2(item:any) {
    if (item === this.selectedItem2) {
      // If the selected item is the same as the current selection, do nothing
      return;
    }

    this.selectedItem2 = item;

    if (this.selectedItem2) {
      this.selectedItem2 = item
      this.isMenuOpen2=false

    }
    this.$emit('selectedItemBitisTarih',this.selectedItem2)

  }
}
